import { Module } from '@fwk-node-modules/vuex';

export const types = {
  SHOW_MENU_ON_MOBILE_SWITCH : "SHOW_MENU_ON_MOBILE_SWITCH",
};

export function createLayoutStore () {

  const store:Module<any, any> = {
    namespaced: true,
    state: {
      showMenuOnMobile : false
    },
    mutations: {
      [types.SHOW_MENU_ON_MOBILE_SWITCH] (state) {
        // We update the store
        state.showMenuOnMobile = !state.showMenuOnMobile;
      },
    },
    actions: {
      
    }
  }
  return store;
};