<template>
  <ul class="nav metismenu" id="side-menu">
    <li class="nav-header">
      <b-dropdown class="profile-element" 
          ref="profile"
          menu-class="animated fadeIn"
          variant="link">
        <template slot="button-content">
          <span class="block m-t-xs font-bold">{{ username }}</span>
          <span class="text-muted text-xs block">{{ menuProfileLink }} <b class="caret"></b></span>
        </template>

        <b-dropdown-item :to="'/'+currentLanguageCode+'/profile'" @click="onProfileLinkClick">{{ $t("menu.profileDropdown.profile") }}</b-dropdown-item>
        <li role="presentation">
          <hr role="separator" aria-orientation="horizontal" class="dropdown-divider">
        </li>
        <b-dropdown-item :href="'/'+currentLanguageCode+'/api/auth/logout'">{{ $t("menu.profileDropdown.logout") }}</b-dropdown-item>
      </b-dropdown>
      <div class="logo-element">
        EF
      </div>
    </li>

    <router-link tag="li" :to="'/'+currentLanguageCode+'/home'" :active-class="activeClass" :key="currentLanguageCode">
      <a>
        <i class="fa fa-th-large"></i>
        <span class="nav-label">{{ $t("menu.modules.home") }}</span>
      </a>
    </router-link>

    <MenuItem v-for="(node, index) in treeModulesAsNodes" :key="index + '-' + node.labelKey" :node="node" :activeClass="'mm-active active'" />

  </ul>
</template>

<style>
  .profile-element.dropdown button {
    margin: 0;
    padding: 0;
    text-align: left;
    color: #dfe4ed;
  }

.profile-element .dropdown-menu a {
    border-radius: 3px;
    color: inherit;
    line-height: 25px;
    margin: 4px;
    text-align: left;
    font-weight: normal;
    padding: 3px 20px;
    display: block;
    color: #212529;
}  
</style>


<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { mapState, mapGetters } from '@fwk-node-modules/vuex';
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { languagesTypes } from '@fwk-client/store/types';
import { enableMetismenu } from '../../theme/inspinia-jquery';
import MenuItem from './MenuItem.vue';

@Component({
  computed: {
    ...mapGetters({
          currentLanguageCode : 'languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE
        }),
  },
  components: {
    MenuItem
  }
})
export default class Menu extends Vue {
  activeClass = "active";

  get username() {
    if(this.$store.state.authentication.isLoggedIn) {
      const displayName = this.$store.state.authentication.user.profile.displayName;
      if(displayName && displayName != "") {
        return displayName;
      }
      else {
        return this.$store.state.authentication.user.login;
      }
    }
    return null;
  }

  get menuProfileLink() {
    return this.$t("menu.profileDropdown.link");
  }

  mounted() {
    // We enable metis menu
    enableMetismenu(this.$store);
  }

  get treeModulesAsNodes() {
    const storeModules = this.$store.state.application.availableModules;

    var treeModules:any = {};

    for(var modulePath of storeModules) {
      var split = modulePath.split("/");
      var root:any = treeModules;

      for(var index = 0 ; index < split.length ; index ++) {
        if(index == split.length - 1) {
          // We are at the end of the tree
          root[split[index]] = modulePath;
        }
        else {
          // We build the tree
          if(!root[split[index]]) {
            root[split[index]] = {}
          }
          // We move to the sub tree
          root = root[split[index]]
        }
      }
    }

    var nodes = this.transformModuleToNodes(treeModules);

    return nodes;
  }

  transformModuleToNodes(treeModules:any, parentKey:string = ""):any[] {
    var nodes = [];
    var treeModuleKeys = Object.keys(treeModules);
    for(var key of treeModuleKeys) {
      var fullKey = parentKey != "" ? parentKey + "/" + key : key;
      var treeModule = treeModules[key];
      if(typeof treeModule === "object") {
        nodes.push({
          labelKey: fullKey,
          nodes: this.transformModuleToNodes(treeModule, fullKey),
          expanded: treeModuleKeys.length == 1 && parentKey == "" // We expand menu by default if there is only one menu item at that level
        })
      }
      else {
        nodes.push({
          labelKey: fullKey,
          path: treeModule
        })
      }
    }
    return nodes;
  }

  onProfileLinkClick() {
    // We force closing the menu as it does not close when clicking on this link the first time.
    // @ts-ignore
    this.$refs.profile.hide(true)
  }

}
</script>