export enum roles {
    shop_admin = "shop-admin", // Access to products, payouts and orders
    shop_admin_methodsOfPayment = "shop-admin-methodsOfPayment", // Access to settings page for method of payment
    shop_admin_methodsOfDelivery = "shop-admin-methodsOfDelivery", // Access to settings page for method of delivery
    shop_admin_promoCodes = "shop-admin-promoCodes", // Access to promo code module
    shop_deploy_shop = "shop-deploy-shop", // Access to deploy page
    shop_deploy_products = "shop-deploy-products", // Access to deploy page
    shop_admin_stock = "shop-admin-stock", // Allow to manage stock of products
    
    shop_availability_create = "shop-availability-create",
    shop_availability_update = "shop-availability-update",
    shop_availability_remove = "shop-availability-remove",
    shop_availability_deploy = "shop-availability-deploy"
}