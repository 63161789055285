import { roles } from '@igotweb-node-api/api/roles/intrastats';

const IntrastatsCheck = () => import(/* webpackChunkName: "route-module-intrastats-check" */ '../components/modules/intrastats/Check.vue').then(m => m.default);
const IntrastatsFiles = () => import(/* webpackChunkName: "route-module-intrastats-files" */ '../components/modules/intrastats/Files.vue').then(m => m.default);
const IntrastatsGenerate = () => import(/* webpackChunkName: "route-module-intrastats-generate" */ '../components/modules/intrastats/Generate.vue').then(m => m.default);

export const routes = [
  {
    path: '/intrastats/check',
    component: IntrastatsCheck,
    meta: {
      layout: "Portal",
      languageBundle: 'intrastats_check',
      authRoles: [roles.intrastats_guest],
      scrollToTop: true
    }
  },
  {
    path: '/intrastats/files',
    component: IntrastatsFiles,
    meta: {
      layout: "Portal",
      languageBundle: 'intrastats_files',
      authRoles: [roles.intrastats_admin],
      scrollToTop: true
    }
  },
  {
    path: '/intrastats/generate',
    component: IntrastatsGenerate,
    meta: {
      layout: "Portal",
      languageBundle: 'intrastats_generate',
      authRoles: [roles.intrastats_admin],
      scrollToTop: true
    }
  }
]