import { roles } from '@igotweb/core-api/src/roles';

const IgotWebInvoices = () => import(/* webpackChunkName: "route-module-igot-web-invoices" */ '../components/modules/igot-web/Invoices.vue').then(m => m.default);

export const routes = [
  {
    path: '/igot-web/invoices',
    component: IgotWebInvoices,
    meta: {
      layout: "Portal",
      authRoles: [roles.user],
      scrollToTop: true
    }
  },
]