import { roles } from '@igotweb-node-api/api/roles/eurofiscalis';

const EurofiscalisContracts = () => import(/* webpackChunkName: "route-module-eurofiscalis-contracts" */ '../components/modules/eurofiscalis/Contracts.vue').then(m => m.default);

export const routes = [
  {
    path: '/eurofiscalis/contracts',
    component: EurofiscalisContracts,
    meta: {
      layout: "Portal",
      languageBundle: ['eurofiscalis_contracts'],
      authRoles: [roles.eurofiscalis_admin],
      scrollToTop: true
    }
  }
]