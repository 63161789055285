<template>
  <nav class="navbar navbar-static-top" role="navigation" style="margin-bottom: 0">
    <div class="navbar-header">
      <a class="navbar-minimalize minimalize-styl-2 btn btn-primary " href="#" @click="onMiniNavbarClick"><i class="fa fa-bars"></i> </a>
      <form v-if="isSearchAllowed" role="search" class="navbar-form-custom" method="post" action="#">
        <div class="form-group">
          <input type="text" placeholder="Search for something..." class="form-control" name="top-search" id="top-search">
        </div>
      </form>
    </div>
    <ul class="nav navbar-top-links navbar-right">
      <b-nav-item-dropdown v-if="isLanguageSwitchAllowed" class="language-selector">
          <img slot="button-content" :src="require('@public/img/flags/'+currentLanguageCode+'.png')" :alt="$t('languages.'+currentLanguageCode)"/>
          <b-dropdown-item v-for="(languageCode, index) in availableLanguages" :key="index" @click="onLanguageClick(languageCode)" :data-language="languageCode">
              <img :src="require('@public/img/flags/' + languageCode + '.png')" :alt="$t('languages.'+ languageCode)" /> {{$t('languages.' + languageCode)}}
          </b-dropdown-item>
      </b-nav-item-dropdown>
      <li v-if="isConfigAllowed">
        <router-link :to="'/'+currentLanguageCode+'/configuration'">
          <i class="fa fa-cogs"></i>
        </router-link>
      </li>
      <li>
        <a :href="'/'+currentLanguageCode+'/api/auth/logout'">
          <i class="fa fa-sign-out"></i> {{ $t('header.logout') }}
        </a>
      </li>
    </ul>
  </nav>
</template>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { mapState, mapGetters } from '@fwk-node-modules/vuex';
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { types as applicationTypes } from '../../store/application';
import { languagesTypes, metaTypes } from '@fwk-client/store/types';
import { smoothlyMenu } from '../../theme/inspinia';
import { utils } from '@fwk-client/router/utils';

@Component({
  components: {},
  computed: {
    ...mapGetters({
          currentLanguageCode : 'languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE,
          isLanguageSwitchAllowed : 'application/' + applicationTypes.getters.IS_LANGUAGE_SWITCH_ALLOWED,
          isConfigAllowed : 'application/' + applicationTypes.getters.IS_CONFIG_ALLOWED
        }),
    ...mapState({
          availableLanguages : (state:any) => state.languages.availableLanguages
        })
  }
})
export default class Header extends Vue {

  isSearchAllowed:boolean = false;

  onMiniNavbarClick(evt:Event) {
    evt.preventDefault();
    // Open / Close menu in canvas mode
    this.$store.commit('meta/' + metaTypes.mutations.TOGGLE_BODY_CLASS, 'mini-navbar');
    // We ensure that the opening is smooth
    smoothlyMenu(this.$store);
  }

  onLanguageClick(languageCode:string) {
        // We redirect to the new language path
        this.$router.push(utils.getRoutePathWithLanguage(this.$store.state.route, languageCode)).catch(err => {});
    }

}
</script>