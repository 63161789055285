<template>
  <router-link v-if="node.path" tag="li" :to="'/'+currentLanguageCode+'/' + node.path" :key="currentLanguageCode" :active-class="activeClass ? activeClass : 'active'" exact>
    <a href="#" v-if="level == 1">
      <i class="fa fa-th-large"></i>
      <span class="nav-label">{{ $t("menu.modules." + node.labelKey) }}</span>
    </a>
    <a href="#" v-else>
      {{ $t("menu.modules." + node.labelKey) }}
    </a>
  </router-link>
  <li v-else :class="nodeRootClass" ref="nodeRoot">
    <a href="#" :aria-expanded="ariaExpanded" v-if="level == 1">
      <i class="fa fa-th-large"></i>
      <span class="nav-label" :key="currentLanguageCode">{{ $t("menu.modules." + node.labelKey) }}</span>
      <span v-if="node.nodes" class="fa arrow"></span>
    </a>
    <a href="#" :aria-expanded="ariaExpanded" v-else>
      {{ $t("menu.modules." + node.labelKey) }}
      <span v-if="node.nodes" class="fa arrow"></span>
    </a>
    <ul v-if="node.nodes" :class="subMenuClass" ref="subMenu">
      <MenuItem v-for="(subNode, index) in node.nodes" :key="index + '-' + subNode.labelKey" :node="subNode" :activeClass="activeClass" :level="level + 1" />
    </ul>
  </li>
</template>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop } from '@fwk-node-modules/vue-property-decorator';
import { mapState, mapGetters } from '@fwk-node-modules/vuex';
import { languagesTypes } from '@fwk-client/store/types';

@Component({
  computed: {
    ...mapGetters({
          currentLanguageCode : 'languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE
        }),
  },
})
export default class MenuItem extends Vue {

  @Prop({
    type: Object,
    required: true
  }) readonly node!: any

  @Prop({
    type: Number,
    required: false,
    default: 1
  }) readonly level!: number

  @Prop({
    type: String,
    required: false
  }) readonly activeClass!: string

  get ariaExpanded() {
    if(this.node.expanded) {
      return "true";
    }
    return "false"
  }

  nodeRootClass = this.node && this.node.expanded ? "mm-active" : null;

  get subMenuClass() {
    var cssClass = "";
    switch(this.level) {
      case 1:
        cssClass = "nav nav-second-level mm-collapse";
        break;
      case 2:
        cssClass = "nav nav-third-level mm-collapse";
        break;
    }
    if(this.node.expanded) {
      cssClass += " mm-show";
    }
    return cssClass;
  } 

  beforeUpdate() {
    if(this.node.nodes) {
      // @ts-ignore
      this.nodeRootClass = this.$refs['nodeRoot'].className;

      // @ts-ignore
      this.node.expanded = this.$refs['subMenu'].className.indexOf("mm-show") > -1;
    }
  }

}
</script>