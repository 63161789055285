import { roles } from '@igotweb-node-api/api/modules/vat-validation/src/roles';

const VatValidationGenerate = () => import(/* webpackChunkName: "route-module-vat-validation-generate" */ '../components/pages/Generate.vue').then(m => m.default);
const VatValidationFiles = () => import(/* webpackChunkName: "route-module-vat-validation-files" */ '../components/pages/Files.vue').then(m => m.default);
const VatValidationSettings = () => import(/* webpackChunkName: "route-module-vat-validation-settings" */ '../components/pages/Settings.vue').then(m => m.default);

const AdminVatValidation = () => import(/* webpackChunkName: "route-module-admin-vat-validation" */ '../components/pages/admin/Modules.vue').then(m => m.default);

export const routes = [
  {
    path: '/vat-validation/generate',
    component: VatValidationGenerate,
    meta: {
      layout: "Portal",
      languageBundle: ['home','vat-validation'],
      authRoles: [roles.vat_validation_generate],
      scrollToTop: true
    }
  },
  {
    path: '/vat-validation/files',
    component: VatValidationFiles,
    meta: {
      layout: "Portal",
      languageBundle: ['home','vat-validation'],
      authRoles: [roles.vat_validation_admin],
      scrollToTop: true
    }
  },
  {
    path: '/vat-validation/settings',
    component: VatValidationSettings,
    meta: {
      layout: "Portal",
      languageBundle: ['home','vat-validation'],
      authRoles: [roles.vat_validation_settings],
      scrollToTop: true
    }
  },
  {
    path: '/admin/vat-validation',
    component: AdminVatValidation,
    meta: {
      layout: "Portal",
      authRoles: ['user'],
      scrollToTop: true,
      languageBundle: ['vat-validation'],
    }
  },
]