import Vue from '@fwk-node-modules/vue';
import { Module } from '@fwk-node-modules/vuex';

export const types = {
  getters: {
    GET_REGISTER_PAYMENT : "GET_REGISTER_PAYMENT",
    GET_NUMBER_OF_WORKERS : "GET_NUMBER_OF_WORKERS"
  },
  mutations : {
    WORKERS_REGISTER_UPDATE_PAYMENT : 'WORKERS_REGISTER_UPDATE_PAYMENT',
    WORKERS_SET_ACTIVE_CONTRACT : 'WORKERS_SET_ACTIVE_CONTRACT',
    WORKERS_SET_NUMBER_OF_WORKERS : 'WORKERS_SET_NUMBER_OF_WORKERS'
  }
}

export function createEurofiscalisStore () {

  const store:Module<any, any> = {
    namespaced: true,
    state: {
      workers : {
        register : {
          payment : null
        },
        contract : null, // The active contract if any.
        numberOfWorkers : null  // The number of workers added by the user
      }
    },
    getters: {
      /**
       * GET_REGISTER_PAYMENT
       * This getter returns if adding worker is allowed
       */
      [types.getters.GET_REGISTER_PAYMENT] (state):any {
        return state.workers.register.payment;
      },
      /**
       * GET_NUMBER_OF_WORKERS
       * This getter returns the number of workers
       */
      [types.getters.GET_NUMBER_OF_WORKERS] (state):any {
        return state.workers.numberOfWorkers;
      },
    },
    mutations: {
      [types.mutations.WORKERS_REGISTER_UPDATE_PAYMENT] (state, payment) {
        // We update the store
        Vue.set(state.workers.register, 'payment', payment);
      },
      [types.mutations.WORKERS_SET_ACTIVE_CONTRACT] (state, contract) {
        // We update the store
        Vue.set(state.workers, 'contract', contract);
      },
      [types.mutations.WORKERS_SET_NUMBER_OF_WORKERS] (state, numberOfWorkers) {
        // We update the store
        Vue.set(state.workers, 'numberOfWorkers', numberOfWorkers);
      }
    }
    
  }
  return store;
};