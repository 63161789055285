import { References } from '@fwk-client/store/languages';
import { roles } from '@igotweb-node-api/api/modules/cms/src/roles';

const SiteTheme = () => import(/* webpackChunkName: "route-module-site-theme" */ '../components/pages/Theme.vue').then(m => m.default);
const SiteRoutes = () => import(/* webpackChunkName: "route-module-site-routes" */ '../components/pages/Routes.vue').then(m => m.default);
const SiteUsers = () => import(/* webpackChunkName: "route-module-site-users" */ '../components/pages/Users.vue').then(m => m.default);
const SiteDeployment = () => import(/* webpackChunkName: "route-module-site-deployment" */ '../components/pages/Deployment.vue').then(m => m.default);

export const routes = [
  {
    path: '/cms/deployment/:siteID?',
    component: SiteDeployment,
    meta: {
      layout: "Portal",
      languageBundle: ['home','cms'],
      authRoles: [roles.site_deploy_site],
      scrollToTop: true
    }
  },
  {
    path: '/cms/theme/:siteID?',
    component: SiteTheme,
    meta: {
      layout: "Portal",
      languageBundle: ['home','cms'],
      authRoles: [roles.site_admin_customization],
      scrollToTop: true,
      languageReferences: [References.COUNTRIES]
    }
  },
  {
    path: '/cms/routes/:siteID?',
    component: SiteRoutes,
    meta: {
      layout: "Portal",
      languageBundle: ['home','cms'],
      authRoles: [roles.site_admin_routes],
      scrollToTop: true,
      languageReferences: [References.COUNTRIES]
    }
  },
  {
    path: '/cms/users/:siteID?',
    component: SiteUsers,
    meta: {
      layout: "Portal",
      languageBundle: ['home','cms','profile'],
      authRoles: [roles.site_admin_users],
      scrollToTop: true
    }
  }
]