import { References } from '@fwk-client/store/languages';
import { roles } from '@igotweb-node-api/api/modules/shop/src/roles';

const ShopOrders = () => import(/* webpackChunkName: "route-module-shop-orders" */ '../components/pages/Orders.vue').then(m => m.default);
const ShopPromoCodes = () => import(/* webpackChunkName: "route-module-shop-promoCodes" */ '../components/pages/PromoCodes.vue').then(m => m.default);
const ShopProducts = () => import(/* webpackChunkName: "route-module-shop-products" */ '../components/pages/Products.vue').then(m => m.default);
const ShopActivities = () => import(/* webpackChunkName: "route-module-shop-activities" */ '../components/pages/Activities.vue').then(m => m.default);
const ShopStock = () => import(/* webpackChunkName: "route-module-shop-stock" */ '../components/pages/Stock.vue').then(m => m.default);
const ShopSettings = () => import(/* webpackChunkName: "route-module-shop-settings" */ '../components/pages/Settings.vue').then(m => m.default);
const ShopDeployment = () => import(/* webpackChunkName: "route-module-shop-deployment" */ '../components/pages/Deployment.vue').then(m => m.default);
const ShopPayouts = () => import(/* webpackChunkName: "route-module-shop-payouts" */ '../components/pages/Payouts.vue').then(m => m.default);

export const routes = [
  {
    path: '/shop/orders/:shopID?',
    component: ShopOrders,
    meta: {
      layout: "Portal",
      languageBundle: ['home','shop','cms','vigneron-online'],
      authRoles: [roles.shop_admin],
      scrollToTop: true,
      languageReferences: [References.COUNTRIES]
    }
  },
  {
    path: '/shop/promoCodes/:shopID?',
    component: ShopPromoCodes,
    meta: {
      layout: "Portal",
      languageBundle: ['home','shop','cms','vigneron-online'],
      authRoles: [roles.shop_admin_promoCodes],
      scrollToTop: true,
      languageReferences: [References.COUNTRIES]
    }
  },
  {
    path: '/shop/products/:shopID?',
    component: ShopProducts,
    meta: {
      layout: "Portal",
      languageBundle: ['home','shop','cms','vigneron-online'],
      authRoles: [roles.shop_admin],
      scrollToTop: true
    }
  },
  {
    path: '/shop/activities/:shopID?',
    component: ShopActivities,
    meta: {
      layout: "Portal",
      languageBundle: ['home','shop','cms','vigneron-online'],
      authRoles: [roles.shop_admin],
      scrollToTop: true
    }
  },
  {
    path: '/shop/stock/:shopID?',
    component: ShopStock,
    meta: {
      layout: "Portal",
      languageBundle: ['home','shop','cms','vigneron-online'],
      authRoles: [roles.shop_admin_stock],
      scrollToTop: true
    }
  },
  {
    path: '/shop/deployment/:shopID?',
    component: ShopDeployment,
    meta: {
      layout: "Portal",
      languageBundle: ['home','shop','cms','vigneron-online'],
      authRoles: [roles.shop_admin],
      scrollToTop: true
    }
  },
  {
    path: '/shop/settings/:shopID?',
    component: ShopSettings,
    meta: {
      layout: "Portal",
      languageBundle: ['home','shop','cms','vigneron-online'],
      authRoles: [roles.shop_admin],
      scrollToTop: true,
      languageReferences: [References.COUNTRIES]
    }
  },
  {
    path: '/shop/payouts/:shopID?',
    component: ShopPayouts,
    meta: {
      layout: "Portal",
      languageBundle: ['home','shop','cms','vigneron-online'],
      authRoles: [roles.shop_admin],
      scrollToTop: true
    }
  }
]