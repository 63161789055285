import { roles } from '@igotweb/core-api/src/roles';

const toolsBank = () => import(/* webpackChunkName: "route-module-tools-bank" */ '../components/modules/tools/Bank.vue').then(m => m.default);
const toolsCompanies = () => import(/* webpackChunkName: "route-module-tools-companies" */ '../components/modules/tools/Companies.vue').then(m => m.default);
const toolsMisc = () => import(/* webpackChunkName: "route-module-tools-misc" */ '../components/modules/tools/Misc.vue').then(m => m.default);
const toolsShipping = () => import(/* webpackChunkName: "route-module-tools-shipping" */ '../components/modules/tools/Shipping.vue').then(m => m.default);

export const routes = [
  {
    path: '/tools/companies',
    component: toolsCompanies,
    meta: {
      layout: "Portal",
      languageBundle: ['tools','company'],
      authRoles: [roles.user],
      scrollToTop: true
    }
  },
  {
    path: '/tools/shipping',
    component: toolsShipping,
    meta: {
      layout: "Portal",
      languageBundle: ['tools','company'],
      authRoles: [roles.user],
      scrollToTop: true
    }
  },
  {
    path: '/tools/bank',
    component: toolsBank,
    meta: {
      layout: "Portal",
      languageBundle: ['tools','bank'],
      authRoles: [roles.user],
      scrollToTop: true
    }
  },
  {
    path: '/tools/misc',
    component: toolsMisc,
    meta: {
      layout: "Portal",
      languageBundle: ['tools'],
      authRoles: [roles.user],
      scrollToTop: true
    }
  }
]