const AdminUsers = () => import(/* webpackChunkName: "route-module-admin-users" */ '../components/modules/admin/Users.vue').then(m => m.default);
const AdminCompanies = () => import(/* webpackChunkName: "route-module-admin-companies" */ '../components/modules/admin/Companies.vue').then(m => m.default);
const AdminCms = () => import(/* webpackChunkName: "route-module-admin-cms" */ '../modules/cms/components/pages/admin/Cms.vue').then(m => m.default);
const AdminShops = () => import(/* webpackChunkName: "route-module-admin-shops" */ '../modules/shop/components/pages/admin/Shops.vue').then(m => m.default);
const AdminBlogs = () => import(/* webpackChunkName: "route-module-admin-blogs" */ '../modules/blog/components/pages/admin/Blogs.vue').then(m => m.default);
const AdminDatabase = () => import(/* webpackChunkName: "route-module-admin-database" */ '../components/modules/admin/Database.vue').then(m => m.default);
const AdminApplication = () => import(/* webpackChunkName: "route-module-admin-application" */ '../components/modules/admin/Application.vue').then(m => m.default);
const AdminStorage = () => import(/* webpackChunkName: "route-module-admin-storage" */ '../components/modules/admin/Storage.vue').then(m => m.default);
const AdminEurofiscalis = () => import(/* webpackChunkName: "route-module-admin-eurofiscalis" */ '../components/modules/admin/Eurofiscalis.vue').then(m => m.default);
const AdminStats = () => import(/* webpackChunkName: "route-module-admin-stats" */ '../components/modules/admin/Stats.vue').then(m => m.default);


export const routes = [
  {
    path: '/admin/users',
    component: AdminUsers,
    meta: {
      layout: "Portal",
      authRoles: ['user'],
      scrollToTop: true,
      languageBundle: ['profile']
    }
  },
  {
    path: '/admin/companies',
    component: AdminCompanies,
    meta: {
      layout: "Portal",
      authRoles: ['user'],
      scrollToTop: true,
      languageBundle: ['admin_companies','company'],
    }
  },
  {
    path: '/admin/cms',
    component: AdminCms,
    meta: {
      layout: "Portal",
      authRoles: ['user'],
      scrollToTop: true,
      languageBundle: ['cms'],
    }
  },
  {
    path: '/admin/shops',
    component: AdminShops,
    meta: {
      layout: "Portal",
      authRoles: ['user'],
      scrollToTop: true,
      languageBundle: ['shop','vigneron-online'],
    }
  },
  {
    path: '/admin/blogs',
    component: AdminBlogs,
    meta: {
      layout: "Portal",
      authRoles: ['user'],
      scrollToTop: true,
      languageBundle: ['blog'],
    }
  },
  {
    path: '/admin/database',
    component: AdminDatabase,
    meta: {
      layout: "Portal",
      authRoles: ['user'],
      scrollToTop: true
    }
  },
  {
    path: '/admin/storage',
    component: AdminStorage,
    meta: {
      layout: "Portal",
      authRoles: ['user'],
      scrollToTop: true
    }
  },
  {
    path: '/admin/application',
    component: AdminApplication,
    meta: {
      layout: "Portal",
      authRoles: ['user'],
      scrollToTop: true
    }
  },
  {
    path: '/admin/eurofiscalis',
    component: AdminEurofiscalis,
    meta: {
      layout: "Portal",
      authRoles: ['user'],
      scrollToTop: true,
      languageBundle: ['company','admin_companies','admin_eurofiscalis'],
    }
  },
  {
    path: '/admin/stats',
    component: AdminStats,
    meta: {
      layout: "Portal",
      authRoles: ['user'],
      scrollToTop: true,
      languageBundle: [],
    }
  },
]