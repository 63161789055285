import { roles } from '@igotweb-node-api/api/modules/hospitality/src/roles';

const AgencyApartments = () => import(/* webpackChunkName: "route-module-agency-apartments" */ '../components/pages/Apartments.vue').then(m => m.default);
const AgencyBookings = () => import(/* webpackChunkName: "route-module-agency-bookings" */ '../components/pages/Bookings.vue').then(m => m.default);
const AgencyAvailabilities = () => import(/* webpackChunkName: "route-module-agency-availabilities" */ '../components/pages/Availabilities.vue').then(m => m.default);
const AgencyDeployment = () => import(/* webpackChunkName: "route-module-agency-deployment" */ '../components/pages/Deployment.vue').then(m => m.default);
const AgencySettings = () => import(/* webpackChunkName: "route-module-agency-settings" */ '../components/pages/Settings.vue').then(m => m.default);

const AdminHospitality = () => import(/* webpackChunkName: "route-module-admin-hospitality" */ '../components/pages/admin/Agencies.vue').then(m => m.default);

export const routes = [
  {
    path: '/hospitality/bookings/:agencyID/booking/:bookingID',
    component: AgencyBookings,
    meta: {
      layout: "Portal",
      languageBundle: ['home','hospitality'],
      authRoles: [roles.hospitality_admin],
      scrollToTop: true
    }
  },
  {
    path: '/hospitality/bookings/:agencyID?',
    component: AgencyBookings,
    meta: {
      layout: "Portal",
      languageBundle: ['home','hospitality'],
      authRoles: [roles.hospitality_admin],
      scrollToTop: true
    }
  },
  {
    path: '/hospitality/apartments/:agencyID?',
    component: AgencyApartments,
    meta: {
      layout: "Portal",
      languageBundle: ['home','hospitality'],
      authRoles: [roles.hospitality_admin],
      scrollToTop: true
    }
  },
  {
    path: '/hospitality/deployment/:agencyID?',
    component: AgencyDeployment,
    meta: {
      layout: "Portal",
      languageBundle: ['home','hospitality'],
      authRoles: [roles.hospitality_admin],
      scrollToTop: true
    }
  },
  {
    path: '/hospitality/availabilities/:agencyID?',
    component: AgencyAvailabilities,
    meta: {
      layout: "Portal",
      languageBundle: ['home','hospitality'],
      authRoles: [roles.hospitality_admin],
      scrollToTop: true
    }
  },
  {
    path: '/hospitality/settings/:agencyID?',
    component: AgencySettings,
    meta: {
      layout: "Portal",
      languageBundle: ['home','hospitality'],
      authRoles: [roles.hospitality_admin],
      scrollToTop: true
    }
  },
  {
    path: '/admin/hospitality',
    component: AdminHospitality,
    meta: {
      layout: "Portal",
      authRoles: ['user'],
      scrollToTop: true,
      languageBundle: ['hospitality'],
    }
  },
]