/// <reference path="../../../node_modules/metismenu/types/metismenu.d.ts" />

import $ from 'jquery';
import { fixHeight } from './inspinia';
import { Store } from '@fwk-node-modules/vuex';

if(process.env.VUE_ENV == 'client') {
    require('metismenu/dist/metisMenu.js');
}

/**********************************************************************
 * JQUERY NON MIGRATED FEATURES
 **********************************************************************/

export const enableIbox = function(rootElement:HTMLElement) {
    // Collapse ibox function
    $('.collapse-link', rootElement).on('click', function (e) {
        e.preventDefault();
        var ibox = $(this).closest('div.ibox');
        var button = $(this).find('i');
        var content = ibox.children('.ibox-content');
        content.slideToggle(200);
        button.toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');
        ibox.toggleClass('').toggleClass('border-bottom');
        setTimeout(function () {
            ibox.resize();
            ibox.find('[id^=map-]').resize();
        }, 50);
    });

    // Close ibox function
    $('.close-link', rootElement).on('click', function (e) {
        e.preventDefault();
        var content = $(this).closest('div.ibox');
        content.remove();
    });

    // Fullscreen ibox function
    $('.fullscreen-link', rootElement).on('click', function (e) {
        e.preventDefault();
        var ibox = $(this).closest('div.ibox');
        var button = $(this).find('i');
        $('body').toggleClass('fullscreen-ibox-mode');
        button.toggleClass('fa-expand').toggleClass('fa-compress');
        ibox.toggleClass('fullscreen');
        setTimeout(function () {
            $(window).trigger('resize');
        }, 100);
    });
}

export const disableIbox = function(rootElement:HTMLElement) {
    $('.collapse-link', rootElement).off();
    $('.close-link', rootElement).off();
    $('.fullscreen-link', rootElement).off();
}

export const enableMetismenu = function(store:Store<any>) {
    const bodyClasses = store.state.meta.bodyClasses;
    var isCollapsedMenu = bodyClasses.indexOf('mini-navbar')>-1
    
    var sideMenu = $('#side-menu');

    // We remove the previous instance
    sideMenu.metisMenu('dispose');

    if(isCollapsedMenu) {
        // When menu is collapsed we want that switch of menu collapse the other ones.
        sideMenu.metisMenu({
            toggle: true
          });
    }
    else {
        // When menu is expanded we want to keep the expanded menus.
        sideMenu.metisMenu({
            toggle: false
          });
    }
    
    sideMenu.on('shown.metisMenu', function (e:Event) {
        fixHeight(store);
    });
}