import {localize} from "vee-validate";
import { Module } from '@fwk-node-modules/vuex';


export const types = {
  getters : {
    IS_LANGUAGE_SWITCH_ALLOWED : 'IS_LANGUAGE_SWITCH_ALLOWED',
    IS_CONFIG_ALLOWED : 'IS_CONFIG_ALLOWED',
    
    GET_USER_SHOPS : 'GET_USER_SHOPS',
    GET_USER_SELECTED_SHOP : 'GET_USER_SELECTED_SHOP',
    GET_USER_ORDERS : 'GET_USER_ORDERS',
    GET_USER_PRODUCTS_CATEGORIES : 'GET_USER_PRODUCTS_CATEGORIES',
    GET_USER_ACTIVITIES_CATEGORIES : 'GET_USER_ACTIVITIES_CATEGORIES',
    GET_USER_PRODUCTS : 'GET_USER_PRODUCTS',
    GET_USER_ACTIVITIES : 'GET_USER_ACTIVITIES',

    GET_USER_SHOP_CONTENTS : 'GET_USER_SHOP_CONTENTS',
    GET_USER_SELECTED_SHOP_CONTENT : 'GET_USER_SELECTED_SHOP_CONTENT',
    
    GET_USER_SITES : 'GET_USER_SITES',
    GET_USER_SELECTED_SITE : 'GET_USER_SELECTED_SITE',

    GET_USER_FEEDS : 'GET_USER_FEEDS',
    GET_USER_SELECTED_FEED : 'GET_USER_SELECTED_FEED',
    GET_USER_POSTS : 'GET_USER_POSTS',

    GET_ADMIN_TARGET_DB : 'GET_ADMIN_TARGET_DB' 
  },
  mutations : {
    SET_AVAILABLE_MODULES : 'SET_AVAILABLE_MODULES',
    SET_IS_LANGUAGE_SWITCH_ALLOWED : 'SET_IS_LANGUAGE_SWITCH_ALLOWED',
    SET_IS_CONFIG_ALLOWED : 'SET_IS_CONFIG_ALLOWED',
    
    SET_USER_SHOPS : 'SET_USER_SHOPS',
    SET_USER_SELECTED_SHOP : 'SET_USER_SELECTED_SHOP',
    SET_USER_ORDERS : 'SET_USER_ORDERS',
    SET_USER_PRODUCTS_CATEGORIES : 'SET_USER_PRODUCTS_CATEGORIES',
    SET_USER_ACTIVITIES_CATEGORIES : 'SET_USER_ACTIVITIES_CATEGORIES',
    SET_USER_PRODUCTS : 'SET_USER_PRODUCTS',
    SET_USER_ACTIVITIES : 'SET_USER_ACTIVITIES',

    SET_USER_SHOP_CONTENTS : 'SET_USER_SHOP_CONTENTS',
    SET_USER_SELECTED_SHOP_CONTENT : 'SET_USER_SELECTED_SHOP_CONTENT',
    
    SET_USER_SITES : 'SET_USER_SITES',
    SET_USER_SELECTED_SITE : 'SET_USER_SELECTED_SITE',
    
    SET_USER_FEEDS : 'SET_USER_FEEDS',
    SET_USER_SELECTED_FEED : 'SET_USER_SELECTED_FEED',
    SET_USER_POSTS : 'SET_USER_POSTS',

    SET_ADMIN_TARGET_DB : 'SET_USER_FEED_TARGET_DB'
  },
  actions : {
    UPDATE_LANGUAGE : 'UPDATE_LANGUAGE'
  }
}

export function createApplicationStore () {

  const applicationStore:Module<any, any> = {
    namespaced: true,
    state: {
      availableModules : [], // These modules are the one available for the logged in user.
      isConfigAllowed : false, // True if the user is allowed to update configuration.
      isLanguageSwitchAllowed : false, // True if the user has possibility to switch language dynamically.
      
      userShops : [], // We store the shop associated to the user on the target shop DB.
      userSelectedShop : null, // We store the production shop associated to the user on the target shop DB.
      userOrders : [], // We store the shop associated orders
      userProducts : [], // We store the shop associated products
      userActivities : [], // We store the shop associated activities
      userProductsCategories : [], // We store the shop associated products categories
      userActivitiesCategories : [], // We store the shop associated activities categories
      
      userShopContents : [], // We store the associated shop content for vigneron-online
      userSelectedShopContent: null, // We store thr production shop content to the user on the target shop DB

      userSites : [], // We store the sites associated to the user
      userSelectedSite : null, // We keep details on the user selected site for admin

      userFeeds : [], // We store the feed associated to the user on the target site DB.
      userSelectedFeed : null, // We store the production feed associated to the user on the target site DB.
      userPosts : [], // We store the list of posts associated to the selected feed.

      adminTargetDB : null // The admin target DB for modules supported
    },
    getters: {
      /**
       * IS_LANGUAGE_SWITCH_ALLOWED
       * This getter returns if language switch is allowed
       */
      [types.getters.IS_LANGUAGE_SWITCH_ALLOWED] (state):any {
        return state.isLanguageSwitchAllowed;
      },
      /**
       * IS_CONFIG_ALLOWED
       * This getter returns if configuration is allowed
       */
      [types.getters.IS_CONFIG_ALLOWED] (state):any {
        return state.isConfigAllowed;
      },
      
      // List of admin elements
      [types.getters.GET_USER_SHOPS] (state):any { return state.userShops; },
      [types.getters.GET_USER_ORDERS] (state):any { return state.userOrders; },
      [types.getters.GET_USER_PRODUCTS_CATEGORIES] (state):any { return state.userProductsCategories; },
      [types.getters.GET_USER_ACTIVITIES_CATEGORIES] (state):any { return state.userActivitiesCategories; },
      [types.getters.GET_USER_PRODUCTS] (state):any { return state.userProducts; },
      [types.getters.GET_USER_ACTIVITIES] (state):any { return state.userActivities; },

      [types.getters.GET_USER_SITES] (state):any { return state.userSites; },
      [types.getters.GET_USER_SHOP_CONTENTS] (state):any { return state.userShopContents; },
      
      [types.getters.GET_USER_FEEDS] (state):any { return state.userFeeds; },
      [types.getters.GET_USER_POSTS] (state):any { return state.userPosts; },

      // Selected admin element
      [types.getters.GET_USER_SELECTED_SHOP] (state):any { return state.userSelectedShop; },
      [types.getters.GET_USER_SELECTED_SITE] (state):any { return state.userSelectedSite; },
      [types.getters.GET_USER_SELECTED_SHOP_CONTENT] (state):any { return state.userSelectedShopContent; },
      [types.getters.GET_USER_SELECTED_FEED] (state):any { return state.userSelectedFeed; },

      [types.getters.GET_ADMIN_TARGET_DB] (state):any { return state.adminTargetDB; },
    },
    mutations: {
      [types.mutations.SET_AVAILABLE_MODULES] (state, modules) {
        // We update the store
        state.availableModules = modules;
      }, 
      [types.mutations.SET_IS_LANGUAGE_SWITCH_ALLOWED] (state, isLanguageSwitchAllowed) {
        // We update the store
        state.isLanguageSwitchAllowed = isLanguageSwitchAllowed;
      },
      [types.mutations.SET_IS_CONFIG_ALLOWED] (state, isConfigAllowed) {
        // We update the store
        state.isConfigAllowed = isConfigAllowed;
      },

      [types.mutations.SET_USER_SITES] (state, sites) { state.userSites = sites; },
      [types.mutations.SET_USER_SELECTED_SITE] (state, site) { state.userSelectedSite = site; },

      [types.mutations.SET_USER_SHOPS] (state, shops) { state.userShops = shops; },
      [types.mutations.SET_USER_SELECTED_SHOP] (state, shop) { state.userSelectedShop = shop; },
      [types.mutations.SET_USER_ORDERS] (state, orders) { state.userOrders = orders; },
      [types.mutations.SET_USER_PRODUCTS_CATEGORIES] (state, categories) { state.userProductsCategories = categories; },
      [types.mutations.SET_USER_ACTIVITIES_CATEGORIES] (state, categories) { state.userActivitiesCategories = categories; },
      [types.mutations.SET_USER_PRODUCTS] (state, products) { state.userProducts = products; },
      [types.mutations.SET_USER_ACTIVITIES] (state, activities) { state.userActivities = activities; },

      [types.mutations.SET_USER_SHOP_CONTENTS] (state, shopContents) { state.userShopContents = shopContents; },
      [types.mutations.SET_USER_SELECTED_SHOP_CONTENT] (state, shopContent) { state.userSelectedShopContent = shopContent; },

      [types.mutations.SET_USER_FEEDS] (state, feeds) { state.userFeeds = feeds; },
      [types.mutations.SET_USER_SELECTED_FEED] (state, feed) { state.userSelectedFeed = feed; },
      [types.mutations.SET_USER_POSTS] (state, posts) { state.userPosts = posts; },

      [types.mutations.SET_ADMIN_TARGET_DB] (state, targetDB) { state.adminTargetDB = targetDB; }
    },
    actions: {
      [types.actions.UPDATE_LANGUAGE]({state, commit, dispatch}, {languageCode}) {
        if(process.env.CONSOLE == "LOG") {
          console.log("APPLICATION STORE - UPDATE LANGUAGE - language:"+languageCode);
        }
        // We localize VeeValidate
        localize(languageCode);
      }
    }
    
  }
  return applicationStore;
};