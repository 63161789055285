import { Module } from '@fwk-node-modules/vuex';

export const types = {
  getters : {
    GET_USER_AVAILABILITIES : 'GET_USER_AVAILABILITIES'
  },
  mutations : {
    SET_USER_AVAILABILITIES : 'SET_USER_AVAILABILITIES'
  },
  actions : {
    
  }
}

export function createShopStore () {

  const shopStore:Module<any, any> = {
    namespaced: true,
    state: {
      userAvailabilities : [], // We store the availabilities associated to the shop
    },
    getters: {
      // List of admin elements
      [types.getters.GET_USER_AVAILABILITIES] (state):any { return state.userAvailabilities; },

      // Selected admin element
    },
    mutations: {
      [types.mutations.SET_USER_AVAILABILITIES] (state, availabilities) { state.userAvailabilities = availabilities; },
    },
    actions: {
    }
    
  }
  return shopStore;
};