const Login = () => import(/* webpackChunkName: "route-login" */ '../components/pages/Login.vue').then(m => m.default);
const AuthenticatorLinks = () => import(/* webpackChunkName: "route-authenticator-links" */ '../components/pages/AuthenticatorLinks.vue').then(m => m.default);

export const routes = [
  {
    path: '/login',
    component: Login,
    meta: {
      scrollToTop: true,
      bodyClasses: ['gray-bg'],
      languageBundle: ['login','profile']
    }
  },
  {
    path: '/authenticator-links',
    component: AuthenticatorLinks,
    meta: {
      scrollToTop: true,
      bodyClasses: ['gray-bg'],
      languageBundle: []
    }
  },
]