import { CurrencyCodes } from "@igotweb-node-api/api/modules/payment/src/models/bean/Currency";

export interface FormatDateOptions {
    type?:FormatDateType;
    isUTC?:boolean;
}

export interface FormatTimeOptions {
    isUTC?:boolean;
}

export enum FormatDateType {
    SHORT = "SHORT",
    LONG = "LONG",
    NUMERIC = "NUMERIC"
}

export function FromLocalToUTC(date:Date) {
    date = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    return date;
}

export function FromUTCToLocal(date:Date) {
    date = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    return date;
}

export function formatDate(date:Date|string, languageCode:string, options?:FormatDateOptions) {

    if(!date) { return ""; }

    // By default the type is short
    var type = (options && options.type) ? options.type : FormatDateType.SHORT;

    var d = new Date(date);

    if(options && options.isUTC) {
        // We update date to be in local timezone
        d = FromUTCToLocal(d)
    }

    if(type==FormatDateType.LONG) {
        return new Intl.DateTimeFormat(languageCode, 
            { 
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
            }).format(d);
    }
    else {
        const ye = new Intl.DateTimeFormat(languageCode, { year: 'numeric' }).format(d)
        const mo = new Intl.DateTimeFormat(languageCode, { month: 'short' }).format(d)
        const da = new Intl.DateTimeFormat(languageCode, { day: '2-digit' }).format(d)
        const time = new Intl.DateTimeFormat(languageCode, { hour: '2-digit', minute: '2-digit' }).format(d)
    
        return `${da} - ${mo} - ${ye} ${time}`
    }
}

export function formatDay(date:Date|string, languageCode:string, options?:FormatDateOptions) {
    if(!(date instanceof Date) && !(typeof date == "string")) {
        return "";
    }

    // By default the type is short
    var type = (options && options.type) ? options.type : FormatDateType.SHORT;

    var d = new Date(date);

    if(options && options.isUTC) {
        // We update date to be in local timezone
        d = FromUTCToLocal(d)
    }

    if(type==FormatDateType.LONG) {
        return new Intl.DateTimeFormat(languageCode, 
            { 
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                weekday: 'long'
            }).format(d);
    }
    else if(type == FormatDateType.NUMERIC) {
        return new Intl.DateTimeFormat(languageCode, 
            { 
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
            }).format(d);
    }
    else {
        const ye = new Intl.DateTimeFormat(languageCode, { year: 'numeric' }).format(d)
        const mo = new Intl.DateTimeFormat(languageCode, { month: 'short' }).format(d)
        const da = new Intl.DateTimeFormat(languageCode, { day: '2-digit' }).format(d)
    
        return `${da} - ${mo} - ${ye}`
    }
}

export function formatMonth(date:Date|string, languageCode:string, options?:FormatDateOptions) {

    // By default the type is short
    var type = (options && options.type) ? options.type : FormatDateType.SHORT;

    var d = new Date(date);

    if(options && options.isUTC) {
        // We update date to be in local timezone
        d = FromUTCToLocal(d)
    }

    if(type==FormatDateType.LONG) {
        return new Intl.DateTimeFormat(languageCode, 
            { 
                year: 'numeric',
                month: 'long'
            }).format(d);
    }
    else {
        const ye = new Intl.DateTimeFormat(languageCode, { year: 'numeric' }).format(d)
        const mo = new Intl.DateTimeFormat(languageCode, { month: 'short' }).format(d)
    
        return `${mo} - ${ye}`
    }
}

export function formatTime(date:Date|string, languageCode:string, options?:FormatTimeOptions) {
    if(!(date instanceof Date) && !(typeof date == "string")) {
        return "";
    }

    var d = new Date(date);

    if(options && options.isUTC) {
        // We update date to be in local timezone
        d = FromUTCToLocal(d)
    }
    
    return new Intl.DateTimeFormat(languageCode, 
        { 
            hour: 'numeric',
            minute: 'numeric',
        }).format(d);
}

function padTo2Digits(num:number) {
return num.toString().padStart(2, '0');
}

export function formatDateForInput(date:Date|string, isUTC?:boolean) {
    if(!(date instanceof Date) && !(typeof date == "string")) {
        return "";
    }

    var d = new Date(date);
    var computedIsUTC = isUTC != undefined ? isUTC : false;

    return [
        computedIsUTC ? d.getUTCFullYear() : d.getFullYear(),
        computedIsUTC ? padTo2Digits(d.getUTCMonth() + 1) : padTo2Digits(d.getMonth() + 1),
        computedIsUTC ? padTo2Digits(d.getUTCDate()) : padTo2Digits(d.getDate()),
      ].join('-');
}

export function formatTimeForInput(date:Date|string, isUTC?:boolean) {
    if(!(date instanceof Date) && !(typeof date == "string")) {
        return "";
    }

    var d = new Date(date);
    var computedIsUTC = isUTC != undefined ? isUTC : false;

    return [
        computedIsUTC ? padTo2Digits(d.getUTCHours()) : padTo2Digits(d.getHours()),
        computedIsUTC ? padTo2Digits(d.getUTCMinutes()) : padTo2Digits(d.getMinutes()),
      ].join('-');
}

export function formatDuration(duration:number) {
    var seconds = Math.floor(duration / 1000);
    var minutes = Math.floor(seconds / 60);
    var hours = Math.floor(minutes / 60);

    seconds = seconds % 60;
    minutes = minutes % 60;

    var formattedDuration = "";
    if(hours > 0) {
        formattedDuration += padTo2Digits(hours) + "h ";
    }
    if(minutes > 0) {
        formattedDuration += padTo2Digits(minutes) + "m ";
    }
    if(seconds > 0) {
        formattedDuration += padTo2Digits(seconds) + "s";
    }

    return formattedDuration.trim();
}

export interface LocalizedTextOptions {
    backup?:boolean; 
    params?:string[];
}

export function getLocalizedText(localizedText:any, languageCode:string, options?:LocalizedTextOptions) {
    var computedOptions = {
        backup:true, // By default if not found in current language we backup to first available languange
        ...options
    }

    // If the localizedText is not defined
    if(!localizedText) {
        return "";
    }

    // Nothing found we return empty string (default value)
    var value = "";

    // If the localized text is not yet stored as an object
    if(typeof localizedText == 'string') {
        value = localizedText;
    }
    // We check if we have a value for the target language.
    else if(localizedText[languageCode]) {
        value = localizedText[languageCode];
    }
    else if(computedOptions.backup) {
        // If not we take the first available value
        var keys = Object.keys(localizedText);
        if(keys && keys.length > 0 && localizedText[keys[0]]) {
            value = localizedText[keys[0]]
        }
    }

    // We compute params if any
    if(computedOptions.params && computedOptions.params.length > 0) {
        for(var indexParam = 0 ; indexParam < computedOptions.params.length ; indexParam++) {
            value = value.replaceAll("{"+indexParam+"}", computedOptions.params[indexParam])
        }
    }
    
    return value;
}

export function formatPrice(amount:number, languageCode:string, currencyCode?:string) {
    if(!currencyCode) { currencyCode = CurrencyCodes.EUR; }
    return new Intl.NumberFormat(languageCode, { style: 'currency', currency: currencyCode }).format(amount);
}

export function getTextFromLocalizedContent(localizedContent:any, languageCode:string, options?:LocalizedTextOptions) {
    var content = getLocalizedText(localizedContent, languageCode, options);
    var text = content.replace(/<[^>]*>?/gm, '');
    return text;
}

export function capitalizeText(str:string) {
    //split the above string into an array of strings 
    //whenever a blank space is encountered
    const arr = str.split(" ");
    
    //loop through each element of the array and capitalize the first letter.
    for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
    }

    //Join all the elements of the array back into a string 
    //using a blankspace as a separator 
    const result = arr.join(" ");
    return result;
}