import { roles } from '@igotweb/core-api/src/roles';

const VigneronOnlineTheme = () => import(/* webpackChunkName: "route-module-vigneron-online-theme" */ '../components/pages/Theme.vue').then(m => m.default);
const VigneronOnlineDeployment = () => import(/* webpackChunkName: "route-module-vigneron-online-deployment" */ '../components/pages/Deployment.vue').then(m => m.default);

export const routes = [
  {
    path: '/vigneron-online/theme/:shopContentID?',
    component: VigneronOnlineTheme,
    meta: {
      layout: "Portal",
      languageBundle: ['vigneron-online','cms','home'],
      authRoles: [roles.user],
      scrollToTop: true
    }
  },
  {
    path: '/vigneron-online/deployment/:shopContentID?',
    component: VigneronOnlineDeployment,
    meta: {
      layout: "Portal",
      languageBundle: ['vigneron-online','cms','home'],
      authRoles: [roles.user],
      scrollToTop: true
    }
  }
]