import { Module } from '@fwk-node-modules/vuex';

export const types = {
  getters : {
    GET_USER_AGENCIES : 'GET_USER_AGENCIES',
    GET_USER_SELECTED_AGENCY : 'GET_USER_SELECTED_AGENCY',
    GET_USER_APARTMENTS : 'GET_USER_APARTMENTS',
    GET_USER_SELECTED_APARTMENT : 'GET_USER_SELECTED_APARTMENT',
    GET_USER_AVAILABILITIES : 'GET_USER_AVAILABILITIES',
    GET_USER_BOOKINGS : 'GET_USER_BOOKINGS'
  },
  mutations : {
    SET_USER_AGENCIES : 'SET_USER_AGENCIES',
    SET_USER_SELECTED_AGENCY : 'SET_USER_SELECTED_AGENCY',
    SET_USER_APARTMENTS : 'SET_USER_APARTMENTS',
    SET_USER_SELECTED_APARTMENT : 'SET_USER_SELECTED_APARTMENT',
    SET_USER_AVAILABILITIES : 'SET_USER_AVAILABILITIES',
    SET_USER_BOOKINGS: 'SET_USER_BOOKINGS'
  },
  actions : {
    
  }
}

export function createHospitalityStore () {

  const hospitalityStore:Module<any, any> = {
    namespaced: true,
    state: {
      userAgencies : [], // We store the agency associated to the user on the target agency DB.
      userSelectedAgency : null, // We store the production agency associated to the user on the target agency DB.
      userApartments : [], // We store the agency associated apartments
      userSelectedApartment : null, // We store the selected apartment
      userAvailabilities : [], // We store the availabilities associated to selected apartment
      userBookings : [], // We store the associated bookings
    },
    getters: {
      // List of admin elements
      [types.getters.GET_USER_AGENCIES] (state):any { return state.userAgencies; },
      [types.getters.GET_USER_APARTMENTS] (state):any { return state.userApartments; },
      [types.getters.GET_USER_AVAILABILITIES] (state):any { return state.userAvailabilities; },
      [types.getters.GET_USER_BOOKINGS] (state):any { return state.userBookings; },

      // Selected admin element
      [types.getters.GET_USER_SELECTED_AGENCY] (state):any { return state.userSelectedAgency; },
      [types.getters.GET_USER_SELECTED_APARTMENT] (state):any { return state.userSelectedApartment; },
    },
    mutations: {
      [types.mutations.SET_USER_AGENCIES] (state, agencies) { state.userAgencies = agencies; },
      [types.mutations.SET_USER_SELECTED_AGENCY] (state, agency) { state.userSelectedAgency = agency; },
      [types.mutations.SET_USER_APARTMENTS] (state, apartments) { state.userApartments = apartments; },
      [types.mutations.SET_USER_SELECTED_APARTMENT] (state, apartment) { state.userSelectedApartment = apartment; },
      [types.mutations.SET_USER_AVAILABILITIES] (state, availabilities) { state.userAvailabilities = availabilities; },
      [types.mutations.SET_USER_BOOKINGS] (state, bookings) { state.userBookings = bookings; },
    },
    actions: {
    }
    
  }
  return hospitalityStore;
};