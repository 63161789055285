<template>
  <div id="app">
    <component :is="layout">
    </component>
  </div>
</template>

<style scoped>
  #app {
    height: 100%;
  }
</style>

<script lang="ts">
import Portal from './components/layouts/Portal.vue';
import Empty from './components/layouts/Empty.vue';
import { messagesTypes, MessageTypes, metaTypes, Message, routerTypes } from '@fwk-client/store/types';
import { checkWindowSize , fixHeight } from './theme/inspinia';
import { toastrMessages } from '@fwk-client/utils/messages';

import { Ref, defineComponent, PropType, computed, onMounted, onUnmounted , onBeforeUnmount, ref, watch, reactive, ComputedRef } from '@fwk-node-modules/vue'
import { getApp, useStore } from '@fwk-client/utils/vue-3-migration';
import { useRoute, useRouter, useLink, onBeforeRouteUpdate, onBeforeRouteLeave } from '@fwk-node-modules/vue-router/composables'

export default defineComponent({
  props: {
  },
  components: {
    Portal
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();
    const $route = useRoute();

    const layoutName:Ref<string | null> = ref(null);

    const layout = computed(() => {
      if(layoutName.value == null) {
        return Empty;
      }
      else if(layoutName.value == "Portal") {
        return Portal;
      }
      else {
        return $store.getters['router/' + routerTypes.getters.GET_LAYOUT](layoutName.value);
      }
    })

    const messages = computed(():Message[] => {
      return $store.getters['messages/' + messagesTypes.getters.GET_MESSAGES];
    })

    onMounted(() => {
      fixHeight($store);
      if(messages.value.length > 0) {
        toastrMessages(app);
      }
    })

    onUnmounted(() => {
      if(typeof window !== 'undefined') {
        window.removeEventListener('resize', onResize);
        window.removeEventListener('load', onLoad);
        window.removeEventListener('scroll', onScroll);
      }
    })

    /**
     * Methods
     */
    const updateLayout = () => {
      // We check the layout configured for the current route.
      layoutName.value = $store.getters['router/' + routerTypes.getters.GET_LAYOUT_NAME_FROM_ROUTE]($route);
    }

    const onLoad = (event:Event) => {
      checkWindowSize($store);
      
      setTimeout(function(){
          if($store.state.meta.bodyClasses.indexOf('body-small') < 0) {
            fixHeight($store);
          }
      });
    }

    const onResize = (event:Event) => {
      checkWindowSize($store);
      
      setTimeout(function(){
          if($store.state.meta.bodyClasses.indexOf('body-small') < 0) {
            fixHeight($store);
          }
      });
    }

    const onScroll = (event:Event) => {
      setTimeout(function(){
          if($store.state.meta.bodyClasses.indexOf('body-small') < 0) {
            fixHeight($store);
          }
      });
    }

    if(typeof window !== 'undefined') {
      window.addEventListener('resize',onResize);
      window.addEventListener('load', onLoad);
      window.addEventListener('scroll', onScroll);
    }
    updateLayout();

    /**
     * Watch
     */
    watch(
      $route,
      (val:any, oldVal:any) => {
       updateLayout();
      },
      { deep: false }
    )
    
    watch(
      messages,
      (messages:Message[], previous:Message[]) => {
       toastrMessages(app);
      },
      { deep: false }
    )

    return {
      layout
    }
  }
});
</script>