import { roles } from '@igotweb-node-api/api/modules/blog/src/roles';

const BlogDeployment = () => import(/* webpackChunkName: "route-module-blog-deployment" */ '../components/pages/Deployment.vue').then(m => m.default);
const BlogFeed = () => import(/* webpackChunkName: "route-module-blog-feed" */ '../components/pages/Feed.vue').then(m => m.default);

export const routes = [
  {
    path: '/blog/deployment/:feedID?',
    component: BlogDeployment,
    meta: {
      layout: "Portal",
      languageBundle: ['home','blog'],
      authRoles: [roles.blog_admin, roles.blog_feed_deploy],
      scrollToTop: true
    }
  },
  {
    path: '/blog/feed/:feedID?',
    component: BlogFeed,
    meta: {
      layout: "Portal",
      languageBundle: ['home','blog'],
      authRoles: [roles.blog_admin],
      scrollToTop: true
    }
  }
]