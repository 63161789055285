import { Module } from '@fwk-node-modules/vuex';
import { GenericStoreModuleTypes } from '@root/src/client/composables/admin/useGenericModuleAdmin';

export const types = {
  getters : {
    GET_USER_MODULES : 'GET_USER_MODULES',
    GET_USER_SELECTED_MODULE : 'GET_USER_SELECTED_MODULE',
    GET_USER_FILES : 'GET_USER_FILES'
  },
  mutations : {
    SET_USER_MODULES : 'SET_USER_MODULES',
    SET_USER_SELECTED_MODULE : 'SET_USER_SELECTED_MODULE',
    SET_USER_FILES : 'SET_USER_FILES'
  },
  actions : {
    
  }
}

export function createAmazonStore () {

  const amazonStore:Module<any, any> = {
    namespaced: true,
    state: {
      userModules : [], // We store the modules associated to the user on the target DB.
      userSelectedModule : null, // We store the production module associated to the user on the target DB.
      userFiles : [], // We store the mpdule associated files
    },
    getters: {
      // List of admin elements
      [types.getters.GET_USER_MODULES] (state):any { return state.userModules; },
      [types.getters.GET_USER_FILES] (state):any { return state.userFiles; },

      // Selected admin element
      [types.getters.GET_USER_SELECTED_MODULE] (state):any { return state.userSelectedModule; }
    },
    mutations: {
      [types.mutations.SET_USER_MODULES] (state, modules) { state.userModules = modules; },
      [types.mutations.SET_USER_SELECTED_MODULE] (state, module) { state.userSelectedModule = module; },
      [types.mutations.SET_USER_FILES] (state, files) { state.userFiles = files; },
    },
    actions: {
    }
    
  }
  return amazonStore;
};