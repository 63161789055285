<template>
  <div id="wrapper">

    <nav class="navbar-default navbar-static-side" role="navigation">
      <div class="sidebar-collapse">
        <Menu></Menu>
      </div>
    </nav>

    <div id="page-wrapper" class="gray-bg">
      <div class="row border-bottom">
        <Header></Header>
      </div>

      <transition @after-leave="afterLeave">
        <router-view></router-view>
      </transition>

      <div class="footer">
        <div class="float-right" v-html="$t('footer.copyright', [year])"></div>
        <div v-html="$t('footer.contact')"></div>
      </div>
      
    </div>

  </div>
</template>

<style>
</style>


<script lang="ts">
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive, ComputedRef } from '@fwk-node-modules/vue'
import { getApp, useStore } from '@fwk-client/utils/vue-3-migration';
import { useRoute, useRouter, useLink, onBeforeRouteUpdate, onBeforeRouteLeave } from '@fwk-node-modules/vue-router/composables'
import Header from '../panels/Header.vue';
import Menu from '../panels/Menu.vue';


export default defineComponent({
  props: {
  },
  components: {
    Header, 
    Menu
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const year = (new Date()).getFullYear();

    onMounted(() => {
      
    })

    /*
   *  Transition afterLeave event
   *  We emit triggerScroll event to be catched by Router.scrollBehavior
   */
    const afterLeave = () => {
      app.$root.$emit('triggerScroll');
    }

    return {
      year,
      afterLeave
    }
  }
});
</script>