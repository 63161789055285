import { roles } from '@igotweb-node-api/api/modules/amazon/src/roles';

const AmazonGenerate = () => import(/* webpackChunkName: "route-module-amazon-generate" */ '../components/pages/Generate.vue').then(m => m.default);
const AmazonFiles = () => import(/* webpackChunkName: "route-module-amazon-files" */ '../components/pages/Files.vue').then(m => m.default);
const AmazonSettings = () => import(/* webpackChunkName: "route-module-amazon-settings" */ '../components/pages/Settings.vue').then(m => m.default);

const AdminAmazon = () => import(/* webpackChunkName: "route-module-admin-amazon" */ '../components/pages/admin/Modules.vue').then(m => m.default);

export const routes = [
  {
    path: '/amazon/generate',
    component: AmazonGenerate,
    meta: {
      layout: "Portal",
      languageBundle: ['home','amazon'],
      authRoles: [roles.amazon_generate],
      scrollToTop: true
    }
  },
  {
    path: '/amazon/files',
    component: AmazonFiles,
    meta: {
      layout: "Portal",
      languageBundle: ['home','amazon'],
      authRoles: [roles.amazon_admin],
      scrollToTop: true
    }
  },
  {
    path: '/amazon/settings',
    component: AmazonSettings,
    meta: {
      layout: "Portal",
      languageBundle: ['home','amazon'],
      authRoles: [roles.amazon_settings],
      scrollToTop: true
    }
  },
  {
    path: '/admin/amazon',
    component: AdminAmazon,
    meta: {
      layout: "Portal",
      authRoles: ['user'],
      scrollToTop: true,
      languageBundle: ['amazon'],
    }
  },
]