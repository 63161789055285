export enum roles {
    hospitality_admin = "hospitality-admin", // Access to apartments, agencies
    hospitality_agency_deploy = "hospitality-agency-deploy", // Access to deploy agency
    hospitality_agency_methodsOfPayment = "hospitality_agency_methodsOfPayment", // Access to settings page for method of payment
    hospitality_apartment_create = "hospitality-apartment-create", // Access to create apartment
    hospitality_apartment_update = "hospitality-apartment-update", // Access to update apartment
    hospitality_apartment_remove = "hospitality-apartment-remove", // Access to remove apartment
    hospitality_apartment_deploy = "hospitality-apartment-deploy", // Access to deploy apartments

    hospitality_availability_create = "hospitality-availability-create", // Access to create availability
    hospitality_availability_update = "hospitality-availability-update", // Access to update availability
    hospitality_availability_remove = "hospitality-availability-remove", // Access to remove availability
    hospitality_availability_deploy = "hospitality-availability-deploy", // Access to deploy availabilities from an apartment
}